body {
  margin: 0;
}

.Results {
  height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.resultsContainer {
  display: flex;
  margin-top: 20vh;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 80vw;
  height: 30rem;
  align-items: center;
}
@media screen and (max-height: 768px) {
  .resultsContainer {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    flex-direction: column;
    overflow-y: auto;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.4rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.3rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
