@media screen and (min-height: 479px) {
  .cardContainer {
    border-radius: 1.3rem;
    margin: 0 1.3rem;
  }
}
@media screen and (max-height: 479px) {
  .cardContainer {
    border-radius: 1.3rem;
    margin: 1rem 1.3rem;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column-reverse;
  color: white;
  cursor: pointer;
  transition: background-color ease 0.2s;
  transition: transform 500ms ease;
}

.cardContainer:hover {
  transform: scale(1.05);
}
.cardContent {
  display: flex;
  flex-direction: column-reverse;
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.3),
    hsl(0 0% 0% / 0.9)
  );
  border-bottom-right-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
  align-items: center;
}
.cardTitle {
  text-align: center;
  user-select: none;
}

.titleBng {
  min-width: 8rem;
  padding: 0.5rem;
  border-radius: 0.8rem;
  justify-content: center;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.cardSynopsis {
  font-size: 1rem;
  padding: 0 0.8rem;
  height: 10vh;
  overflow-y: scroll;
  transition: 300ms;
}
.cardSynopsis::-webkit-scrollbar {
  display: none !important;
}

.cardContainer:hover .cardSynopsis {
  height: 25vh;
}
