.home-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form-container {
  margin-top: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 21rem;
}

@media screen and (max-height: 768px) {
  .form-container {
    margin-top: 8rem;
  }
}

.incrementContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 10rem;
  font-size: 1.2rem;
}

.search-bar {
  width: 20rem;
  height: 3.5rem;
  padding: 0;
  font-size: 1.1rem;
  outline: none;
  border: none;
  background-color: transparent;
  transition: border-bottom ease 0.3s;
}
