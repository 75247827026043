.navbar-container {
  width: 100vw;
  height: 8rem;
  display: flex;
  align-items: center;
  background-color: white;
}

.icon {
  cursor: pointer;
  transition: color 0.3s;
}

.navbar-link {
  text-decoration: none;
  margin-left: 3rem;
}

@media screen and (max-height: 768px) {
  .navbar-container {
    height: 8rem;
  }
}
