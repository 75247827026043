@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
* {
  font-family: "Roboto Mono", monospace;
  font-size: 100%;
  transition: background-color ease 0.2s;
}

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
    padding-top: min(0vmin, env(safe-area-inset-top));
    padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}

body {
  margin: 0;
  overflow: hidden;
}

.App {
  height: 100vh;
}

.home-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form-container {
  margin-top: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 21rem;
}

@media screen and (max-height: 768px) {
  .form-container {
    margin-top: 8rem;
  }
}

.incrementContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 10rem;
  font-size: 1.2rem;
}

.search-bar {
  width: 20rem;
  height: 3.5rem;
  padding: 0;
  font-size: 1.1rem;
  outline: none;
  border: none;
  background-color: transparent;
  transition: border-bottom ease 0.3s;
}

.Increment {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.7rem;
  z-index: 0;
  font-family: "Roboto Mono", monospace;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: none;
  outline: none;
}

.Submit {
  width: 6rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  z-index: 0;
  font-family: "Roboto Mono", monospace;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: none;
  outline: none;
  padding: 0.2rem;
}

.Autocomplete {
  max-height: 30vh;
  background-color: white;
  flex-direction: column;
  width: 20rem;
  z-index: 1;
  overflow-y: auto;
  position: absolute;
}
.hidden {
  display: none;
}
.Autocomplete::-webkit-scrollbar {
  display: none !important;
}

.AutocompleteLiElement {
  min-height: 7vh;
  max-height: 18vh;
  background-color: white;
  padding: 0.3vw;
  width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
}
.AutocompleteLiElement:hover {
  background-color: darkgray;
}

.Spinner {
  margin: auto;
  align-self: center;
}

body {
  margin: 0;
}

.Results {
  height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.resultsContainer {
  display: flex;
  margin-top: 20vh;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 80vw;
  height: 30rem;
  align-items: center;
}
@media screen and (max-height: 768px) {
  .resultsContainer {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    flex-direction: column;
    overflow-y: auto;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.4rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.3rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (min-height: 479px) {
  .cardContainer {
    border-radius: 1.3rem;
    margin: 0 1.3rem;
  }
}
@media screen and (max-height: 479px) {
  .cardContainer {
    border-radius: 1.3rem;
    margin: 1rem 1.3rem;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column-reverse;
  color: white;
  cursor: pointer;
  transition: background-color ease 0.2s;
  transition: transform 500ms ease;
}

.cardContainer:hover {
  transform: scale(1.05);
}
.cardContent {
  display: flex;
  flex-direction: column-reverse;
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.3),
    hsl(0 0% 0% / 0.9)
  );
  border-bottom-right-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
  align-items: center;
}
.cardTitle {
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.titleBng {
  min-width: 8rem;
  padding: 0.5rem;
  border-radius: 0.8rem;
  justify-content: center;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.cardSynopsis {
  font-size: 1rem;
  padding: 0 0.8rem;
  height: 10vh;
  overflow-y: scroll;
  transition: 300ms;
}
.cardSynopsis::-webkit-scrollbar {
  display: none !important;
}

.cardContainer:hover .cardSynopsis {
  height: 25vh;
}

.splash-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 90vh;
}
.forms-container {
  height: 40vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  height: 50%;
}

.signin-buttons-container {
  display: flex;
}
.socials-signin-container {
  display: flex;
  margin-left: 0.3rem;
}
.social-signin-button {
  border-radius: 0.4rem;
  outline: none;
  border: none;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  margin: 0 0.3rem;
}

.formField {
  width: 13rem;
  height: 2rem;
  padding: 0.5rem;
  margin-bottom: 0.9rem;
  font-size: 0.9rem;
  border-radius: 0.7rem;
  outline: none;
  border: none;
  background-color: transparent;
  transition: border-bottom ease 0.3s;
}

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
}

.account-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
.saved-anime-container {
  height: 57vh;
  width: 70vw;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  flex-direction: column;
}
.anime-cards-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  height: 50vh;
  align-items: center;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-container {
  width: 100vw;
  height: 8rem;
  display: flex;
  align-items: center;
  background-color: white;
}

.icon {
  cursor: pointer;
  transition: color 0.3s;
}

.navbar-link {
  text-decoration: none;
  margin-left: 3rem;
}

@media screen and (max-height: 768px) {
  .navbar-container {
    height: 8rem;
  }
}

.themeButtonContainer {
  height: 2.8rem;
  width: 2.8rem;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  position: absolute;
  right: 3vw;
}
@media screen and (max-height: 769px) {
  .themeButtonContainer {
    right: 2rem;
  }
}

