.account-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 90vh;
}
.forms-container {
  height: 40vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
