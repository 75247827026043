@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

* {
  font-family: "Roboto Mono", monospace;
  font-size: 100%;
  transition: background-color ease 0.2s;
}

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
    padding-top: min(0vmin, env(safe-area-inset-top));
    padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}

body {
  margin: 0;
  overflow: hidden;
}

.App {
  height: 100vh;
}
