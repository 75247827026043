.account-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
.saved-anime-container {
  height: 57vh;
  width: 70vw;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  flex-direction: column;
}
.anime-cards-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  height: 50vh;
  align-items: center;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
