.Increment {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.7rem;
  z-index: 0;
  font-family: "Roboto Mono", monospace;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border: none;
  outline: none;
}
