.AutocompleteLiElement {
  min-height: 7vh;
  max-height: 18vh;
  background-color: white;
  padding: 0.3vw;
  width: 100%;
  user-select: none;
  display: flex;
  align-items: center;
}
.AutocompleteLiElement:hover {
  background-color: darkgray;
}
