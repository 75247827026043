.themeButtonContainer {
  height: 2.8rem;
  width: 2.8rem;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  position: absolute;
  right: 3vw;
}
@media screen and (max-height: 769px) {
  .themeButtonContainer {
    right: 2rem;
  }
}
