.Autocomplete {
  max-height: 30vh;
  background-color: white;
  flex-direction: column;
  width: 20rem;
  z-index: 1;
  overflow-y: auto;
  position: absolute;
}
.hidden {
  display: none;
}
.Autocomplete::-webkit-scrollbar {
  display: none !important;
}
